import React from 'react'
import createSvgIcon from './utils/createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <defs>
      <path
        d="M10 0c5.52 0 10 4.48 10 10s-4.48 10-10 10S0 15.52 0 10 4.48 0 10 0zM7.608 15.528a1.068 1.068 0 001.495 0L17.15 7.61a1.027 1.027 0 000-1.472 1.068 1.068 0 00-1.495 0l-7.294 7.178-3.064-3.004a1.068 1.068 0 00-1.495 0 1.027 1.027 0 000 1.47l3.806 3.746z"
        id="prefix__a"
      />
    </defs>
    <use
      fill="#32DB86"
      fillRule="nonzero"
      xlinkHref="#prefix__a"
      transform="translate(2 2)"
    />
  </React.Fragment>,
  'StatusSuccess',
)
