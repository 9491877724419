import React, { useEffect, useRef, useState } from 'react'
import MuiTab from '@material-ui/core/Tab'
import withStyles from '../../styles/withStyles'
import { TabStyle } from '../../utils/styleKeys'

function Tab({ className, onClick, ...props }) {
  let [active, setActive] = useState(false)
  let timeoutIds = useRef([])

  let registerTimeout = (f, ms) => {
    let timeoutId = setTimeout(f, ms)
    timeoutIds.current.push(timeoutId)
  }

  let handleClick = (e) => {
    e.persist()
    setActive(true)
    if (typeof onClick === 'function') onClick(e)
  }

  let cleanup = () => {
    timeoutIds.current.forEach(clearTimeout)
  }

  useEffect(() => {
    if (active === true) {
      registerTimeout(() => setActive(false), 250)
    }
    return cleanup
  })

  return (
    <MuiTab
      className={`${active ? 'Mui-active' : ''} ${className}`}
      onClick={handleClick}
      {...props}
    />
  )
}

let styles = (theme) => ({
  ...TabStyle,
  root: {
    ...theme.typography.body2,
    color: theme.palette.secondary.dark,
    transition: theme.transitions.common.enteringScreen,
    padding: '14px 16px',
    minWidth: '90px',
    '&:hover, &.Mui-focus, &.Mui-focusVisible': {
      backgroundColor: theme.palette.success['x-light'],
    },
  },
  selected: { color: theme.palette.text.primary },
  disabled: { color: theme.palette.disabled.main },
})

export default withStyles(styles, { name: 'MuiTab' })(Tab)
