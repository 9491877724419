import React from 'react'
import clsx from 'clsx'
import ListItem from '../../core-components/ListItem'
import timeAgo from '../../utils/timeAgo'
import makeStyles from '../../styles/makeStyles'
import StatusError from '../../icons/StatusError'
import StatusNew from '../../icons/StatusNew'
import StatusSuccess from '../../icons/StatusSuccess'
import StatusWarning from '../../icons/StatusWarning'

const statusIcon = {
  error: StatusError,
  info: StatusNew,
  success: StatusSuccess,
  warning: StatusWarning,
}

export default function NotificationItem({
  createdAt,
  link,
  onMarkReadClick,
  onMarkAllReadClick,
  message,
  type,
  read,
  onRedirect,
  ...props
}) {
  useStyles()
  const Icon = statusIcon[type] || StatusNew

  const handleClick = () => {
    onMarkReadClick && onMarkReadClick(props)
    onMarkAllReadClick && onMarkAllReadClick(props)
    if (onRedirect && typeof onRedirect === 'function') {
      onRedirect(link)
    } else {
      window.open(link)
    }
  }

  return (
    <ListItem
      data-testid="NotificationItem"
      className={clsx('NotificationItem', 'NotificationItem-item', {
        ['NotificationItem--isRead']: read,
      })}
      onClick={handleClick}
      selected={read}
    >
      <div className="NotificationItem__grid NotificationItem-grid">
        <Icon className="NotificationItem__icon NotificationItem-icon" />
        <div className="NotificationItem__message NotificationItem-message">
          {message}
        </div>
        <div className="NotificationItem__date NotificationItem-date">
          {timeAgo(createdAt)}
        </div>
      </div>
    </ListItem>
  )
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.NotificationItem': {
      ...theme.typography.caption,
      zIndex: 1,
      backgroundColor: theme.palette.grey[100],

      '&.MuiListItem-root:Mui-selected, &.MuiListItem-root:hover': {
        backgroundColor: theme.palette.grey[100],
      },

      '&.MuiListItem-root:hover': {
        boxShadow: theme.shadows[2],
        zIndex: 10,
      },

      // .NotificationItem--isRead
      '&--isRead': {
        backgroundColor: theme.palette.common.white,

        '&.MuiListItem-root.Mui-selected, &.MuiListItem-root:hover': {
          backgroundColor: theme.palette.common.white,
        },
      },

      // .NotificationItem__grid
      '&__grid': {
        display: 'grid',
        width: '100%',
        gridTemplateColumns: '16px 1fr',
        gridTemplateRows: '1fr 16px',
        gap: '8px',
        gridTemplateAreas: `
          "icon message"
          ".    date"
        `,
      },

      // .NotificationItem__icon
      '&__icon': {
        gridArea: 'icon',
        fontSize: '16px',
      },

      // .NotificationItem__message
      '&__message': {
        gridArea: 'message',
        color: theme.palette.grey[800],
      },

      // .NotificationItem__date
      '&__date': {
        gridArea: 'date',
        color: theme.palette.grey[900],
        fontStyle: 'italic',
        textAlign: 'right',
      },
    },
  },
}))
