import React from 'react'
import createSvgIcon from './utils/createSvgIcon'

export default createSvgIcon(
  <path
    d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm-.559 4.991c-1.376-1.376-3.6-1.383-4.966-.016-1.367 1.367-1.36 3.59.016 4.966a3.526 3.526 0 003.308.943l1.024 1.025 1.586-.016-.096 1.25 1.214-.027.035 1.177 1.209-.038.053 1.273 2.275-.052a.217.217 0 00.215-.224l-.03-1.924-4.926-4.926a3.526 3.526 0 00-.917-3.41zm-4.218.732a.903.903 0 011.278.004.903.903 0 01.004 1.278.903.903 0 01-1.278-.004.903.903 0 01-.004-1.278z"
    fill="#32DB86"
    fillRule="nonzero"
  />,
  'StatusPermission',
)
